import React, { FC } from "react"

import { BoldTeaser, BoldTeaserProps } from "../boldTeaser"
import {
  EditorialTeaser,
  EditorialTeaserProps,
} from "src/components/cck/elements/editorialTeaser"

import { Headline, HeadlineProps } from "src/components/cck/elements/headline"

import {
  InstructionalStep,
  InstructionalStepProps,
} from "src/components/cck/elements/instructionalStep"

import {
  IntroText,
  IntroTextProps,
} from "src/components/cck/elements/introText"

import {
  QuoteTile,
  QuoteTileProps,
} from "src/components/cck/elements/quoteTile"
import {
  SalesTeaser,
  SalesTeaserProps,
} from "src/components/cck/elements/salesTeaser"

import { create } from "src/helpers/bem"

import styles from "./TeaserModule.module.scss"

const bem = create(styles, "TeaserModule")

export type TeaserModuleProps = {
  headline?: HeadlineProps
  introText?: IntroTextProps
  editorialTeasers?: EditorialTeaserProps[]
  salesTeasers?: SalesTeaserProps[]
  instructionalSteps?: InstructionalStepProps[]
  quoteTiles?: QuoteTileProps[]
  boldTeasers?: BoldTeaserProps[]
}

type TeaserAreaProps = Omit<TeaserModuleProps, "headline" | "introText">

const TeaserArea: FC<TeaserAreaProps> = ({
  editorialTeasers,
  salesTeasers,
  instructionalSteps,
  quoteTiles,
  boldTeasers,
}) => {
  if (!!editorialTeasers?.length)
    return (
      <>
        {editorialTeasers.map(
          (editorialTeaser: EditorialTeaserProps, index) => (
            <EditorialTeaser {...editorialTeaser} key={index} />
          ),
        )}
      </>
    )

  if (!!salesTeasers?.length)
    return (
      <>
        {salesTeasers.map((salesTeaser: SalesTeaserProps, index) => (
          <SalesTeaser {...salesTeaser} key={index} />
        ))}
      </>
    )

  if (!!boldTeasers?.length)
    return (
      <>
        {boldTeasers.map((boldTeaser: BoldTeaserProps, index) => (
          <BoldTeaser {...boldTeaser} key={index} />
        ))}
      </>
    )

  if (!!instructionalSteps?.length)
    return (
      <>
        {instructionalSteps.map(
          (instructionalStep: InstructionalStepProps, index) => (
            <InstructionalStep {...instructionalStep} key={index} />
          ),
        )}
      </>
    )

  if (quoteTiles?.length) {
    return (
      <>
        {quoteTiles.map((quoteTile, index) => (
          <QuoteTile key={index} {...quoteTile} />
        ))}
      </>
    )
  }

  return null
}

/**
 * It is a simple presentation area for teasers, which can be scaled as desired.
 *
 * There can be an unlimited number of teasers.
 *
 * It can stand alone with just one or various items.
 *
 * The main difference between the [Slider](../?path=/docs/cck-sections-slider-module--docs) and the [Teaser](..//?path=/docs/cck-sections-teaser-module--docs) module is: the Slider slides and the Teaser Module don't — it breaks to the next line in smaller screens.
 */
export const TeaserModule: FC<TeaserModuleProps> = ({
  headline,
  introText,
  editorialTeasers,
  salesTeasers,
  instructionalSteps,
  quoteTiles,
  boldTeasers,
}) => {
  if (
    !editorialTeasers?.length &&
    !salesTeasers?.length &&
    !instructionalSteps?.length &&
    !quoteTiles?.length &&
    !boldTeasers?.length
  )
    return null

  return (
    <div className={bem()}>
      {!!headline?.children && (
        <Headline
          {...headline}
          level={headline.level ?? "4"}
          highlighted={false}
        />
      )}

      {!!introText?.children && (
        <IntroText {...introText} align={introText.align ?? "center"} />
      )}

      <div className={bem("teaser-area")}>
        <TeaserArea
          editorialTeasers={editorialTeasers}
          salesTeasers={salesTeasers}
          instructionalSteps={instructionalSteps}
          quoteTiles={quoteTiles}
          boldTeasers={boldTeasers}
        />
      </div>
    </div>
  )
}
